<template>
    <div id="reconciliation-main" class="content-wrapper">
        <rq-banner
            variant="error"
            icon="fas fa-exclamation-triangle"
            :message="errorMessage"
            :visible="errorMessage.length > 0"
            @dismiss="errorMessage=''"
            dismissable
        />
        <rq-page-section :title="title" headerSize="lg" borderless header-only>
            <template #header-actions>
                <ul class="nav">
                    <li class="nav-item">
                        <div class="dropdown rq-section-dropdown">
                            <button class="btn btn-theme dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" :disabled="readOnly">Add</button>
                            <ul class="dropdown-menu">
                                <li>
                                    <button type="button" class="dropdown-item" automation_id="btn_add_other_check" @click="onAddOtherDisbursement($event, true)">Other Disbursement</button>
                                </li>
                                <li>
                                    <button type="button" class="dropdown-item" automation_id="btn_add_other_deposit" @click="onAddOtherDisbursement($event, false)">Other Receipt</button>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li class="nav-item">
                        <div class="dropdown rq-section-dropdown">
                            <button class="btn btn-theme dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" :disabled="readOnly">Manage</button>
                            <ul class="dropdown-menu">
                                <li>
                                    <button type="button" class="dropdown-item" automation_id="btn_edit_reconciliation" @click="onEditReconciliation">Initial Balances</button>
                                </li>
                                <li>
                                    <button type="button" class="dropdown-item"  automation_id="btn_refresh_items" @click="onRefreshItems" :disabled="!enableFinalize">Refresh</button>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li class="nav-item">
                        <div class="dropdown rq-section-dropdown">
                            <button class="btn btn-theme dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" :disabled="readOnly">Print</button>
                            <ul class="dropdown-menu">
                                <li>
                                    <button type="button" class="dropdown-item" automation_id="btn_print_bank_reconciliation" @click="onPrint($event, 'Bank Reconciliation')">Bank Reconciliation</button>
                                </li>
                                <li>
                                    <button type="button" class="dropdown-item" automation_id="btn_print_book_balance" @click="onPrint($event, 'Bank Balance')" >Book Balance</button>
                                </li>
                                <li>
                                    <button type="button" class="dropdown-item" automation_id="btn_print_escrow_trial_balance" @click="onPrint($event, 'Escrow Trial Balance')" >Escrow Trial Balance</button>
                                </li>
                                <li>
                                    <button type="button" class="dropdown-item" automation_id="btn_print_reconciled_bank_balance" @click="onPrint($event, 'Reconciled Bank Balance')">Reconciled Bank Balance</button>
                                </li>
                                <li>
                                    <button type="button" class="dropdown-item" automation_id="btn_print_three_way_summary" @click="onPrint($event, '3-Way Summary')">3-Way Summary</button>
                                </li>
                                <li>
                                    <button type="button" class="dropdown-item" automation_id="btn_print_full_three_way_summary" @click="onPrint($event, 'Full 3-Way Summary')">Full 3-Way Summary</button>
                                </li>
                                <li>
                                    <button type="button" class="dropdown-item" automation_id="btn_print_exception" @click="onPrint($event, 'Exceptions Report')">Exceptions Report</button>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li class="nav-item">
                        <div class="dropdown rq-section-dropdown">
                            <button class="btn btn-theme dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" :disabled="readOnly">Clear</button>
                            <ul class="dropdown-menu">
                                <li>
                                    <button type="button" class="dropdown-item" automation_id="btn_select_check_range" @click="onSelectCheckRange" :disabled="!showSelectAllChecks">Clear Check Range</button>
                                </li>
                                <li>
                                    <button type="button" class="dropdown-item" automation_id="btn_select_all_checks" @click="onSelectAllChecks" :disabled="!showSelectAllChecks">Clear All Checks</button>
                                </li>
                                <li>
                                    <button type="button" class="dropdown-item" automation_id="btn_unselect_all_checks" @click="onUnselectAllChecks" :disabled="!showUnselectAllChecks">Undo Clear for All Checks</button>
                                </li>
                                <li>
                                    <button type="button" class="dropdown-item" automation_id="btn_select_all_deposits" @click="onSelectAllDeposits" :disabled="!showSelectAllDeposits">Clear All Deposits</button>
                                </li>
                                <li>
                                    <button type="button" class="dropdown-item" automation_id="btn_unselect_all_deposits" @click="onUnselectAllDeposits" :disabled="!showUnselectAllDeposits">Undo Clear for All Deposits</button>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li class="nav-item">
                        <b-button
                            automation_id="btn_finalize"
                            class="btn btn-theme me-1"
                            variant="theme"
                            @click="onFinalize"
                            :disabled="readOnly || !enableFinalize"
                            >Finalize
                        </b-button>
                    </li>
                </ul>
            </template>
            <template #header-secondary>
                <button automation_id="btn_view_reconcilliations" type="button" class="btn btn-link btn-theme" @click="onViewReconciliationList">View Reconciliation List</button>
            </template>
        </rq-page-section>
        <div class="row ps-3 pe-3">
            <div class="col col-12">
                <reconciliation-summary
                    :reconciliation="reconciliation"
                    :read-only="readOnly"
                    @refresh="onRefreshItems"
                ></reconciliation-summary>
            </div>
        </div>
        <RqTabs
            :tabs="tabItems"
            v-model="tabIndex"
            @activate-tab="onTabActivated">
            <template #receipts>
                <reconciliation-deposit-list
                    ref="receiptsList"
                    :items="deposits"
                    :reconciliation="reconciliation"
                    :read-only="readOnly"
                    @update-error-message="onComponentError"
                    @update-data="onUpdateDepositData"
                ></reconciliation-deposit-list>
            </template>
            <template #disbursements>
                <reconciliation-check-list
                    ref="disbursementsList"
                    :items="checks"
                    :reconciliation="reconciliation"
                    :read-only="readOnly"
                    @update-error-message="onComponentError"
                    @update-data="onUpdateCheckData"
                ></reconciliation-check-list>
            </template>
            <template #adjustments>
                <reconciliation-adjustment-list
                    ref="adjustmentsList"
                    :items="adjustments"
                    :reconciliation="reconciliation"
                    :read-only="readOnly"
                    @update-error-message="onComponentError"
                    @update-data="onUpdateAdjustmentData"
                ></reconciliation-adjustment-list>
            </template>
            <template #balance-sheet>
                <reconciliation-balance-sheet-list
                    ref="balanceSheetList"
                    :items="ledger"
                    :reconciliation="reconciliation"
                    :read-only="readOnly"
                    @update-error-message="onComponentError"
                ></reconciliation-balance-sheet-list>
            </template>
            <template #in-transit>
                <reconciliation-in-transit-list
                    ref="inTransitList"
                    :items="inTransit"
                    :reconciliation="reconciliation"
                    :read-only="readOnly"
                    @update-error-message="onComponentError"
                ></reconciliation-in-transit-list>
            </template>
        </RqTabs>
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { ORDER_ACTIONS } from '@/store/actions';
    import { GlobalEventManager } from "@/app.events";
    import { DateTime } from "luxon";
    import ReconciliationAdjustmentList from "../components/ReconciliationAdjustmentList";
    import ReconciliationAddEditForm from "../components/ReconciliationAddEditForm";
    import ReconciliationBalanceSheetList from "../components/ReconciliationBalanceSheetList";
    import ReconciliationCheckList from "../components/ReconciliationCheckList";
    import ReconciliationDepositList from "../components/ReconciliationDepositList";
    import ReconciliationInTransitList from "../components/ReconciliationInTransitList";
    import ReconciliationSelectCheckRange from "../components/ReconciliationSelectCheckRange";
    import ReconciliationSummary from "../components/ReconciliationSummary";
    import { ReconciliationDto, OtherDisbursementReconciliationDto, ReconciliationBalanceSheetDto, ReconciliationCheckDto, ReconciliationDepositDto, ReconciliationInTransitDto} from "../models";
    import { ReportOptionsDto } from "@reporting/exago-reports/report-models";

    export default {
        name:"Reconciliation",
        components: {
            ReconciliationAdjustmentList,
            ReconciliationSummary,
            ReconciliationBalanceSheetList,
            ReconciliationCheckList,
            ReconciliationDepositList,
            ReconciliationInTransitList
        },
        data () {
            return {
                tabIndex: 0,
                adjustments: [],
                checks: [],
                deposits: [],
                ledger: [],
                inTransit: [],
                reconciliation: {},
                errorMessage: "",
                tabs: {}
            };
        },
        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            ...mapState({
                order: state => state.orders.order,
                user: state => state.authentication.session.user
            }),
            title() { return `${this.lookupHelpers.getLookupItemName(this.lookupItems.ESCROW_ACCOUNTS, _.get(this, "reconciliation.bankCompanyID", 0))} - ${DateTime.fromISO(this.reconciliation.reconciliationDate).toFormat('MM/dd/yyyy')}`; },
            isBalanced() { return this.reconciliation.difference == 0; },
            localSecurity(){
                return this.securitySettings.findValues([
                    "AllowFinalizedReconModifications",
                    "CanProcessEBankRecon",
                    "IsAdmin",
                    "IsEscrowAdmin",
                   ]);
            },
            enableFinalize() { return (!this.reconciliation.completed); },
            showInTransit() { return _.parseBool(this.reconciliation.completed, false); },
            showSelectAllChecks() { return _.some(this.checks, ["clearedTemp", false]); },
            showUnselectAllChecks() { return _.some(this.checks, ["clearedTemp", true]); },
            showSelectAllDeposits() { return _.some(this.deposits, ["clearedTemp", false]); },
            showUnselectAllDeposits() { return _.some(this.deposits, ["clearedTemp", true]); },
            readOnly() { return this.reconciliation.completed && !this.localSecurity.AllowFinalizedReconModifications; },
            reconciliationID() { return _.parseNumber(_.get(this.$route, "params.reconciliationID", 0), 0); },
            tabItems() {
                const self = this;
                return [
                    { automation_id: "btn_tab_receipts", name: "receipts", title: "Receipts" },
                    { automation_id: "btn_tab_disbursements", name: "disbursements", title: "Disbursements" },
                    { automation_id: "btn_tab_adjustments", name: "adjustments", title: "Adjustments" },
                    { automation_id: "btn_tab_balance_sheet", name: "balance-sheet", title: "Balance Sheet" },
                    { automation_id: "btn_tab_in_transit", name: "in-transit", title: "In Transit", visible: self.showInTransit }
                ];
            }
        },
        created(){
            GlobalEventManager.onSave(this, this.onSave);
            this.tabs = {Receipts: 0, Disbursements: 1, Adjustments: 2, BalanceSheet: 3};
            this.fetchData();
        },
        beforeRouteLeave(to, from, next) {
            const self = this;
            self.onSave({ userInitiated: false })
                .then(isValid => {
                    if(!isValid) return;
                    next();
                });
        },
        beforeUnmount() {
            GlobalEventManager.unregister(this);
        },
        methods: {
            fetchData() {
                const self = this;
                self.errorMessage = "";
                let apiPromise = self.$api.ReconciliationApi.getData(self.reconciliationID);
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.errorMessage = "";
                        self.setData(result);
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading Reconciliation Data.` });
                    });
            },

            onAddOtherDisbursement(e, disburse) {
                if(this.readOnly) return;
                if (_.isNil(this.$refs.adjustmentsList)) return;
                this.tabIndex = this.tabs.Adjustments;
                this.$nextTick().then(() => {
                    this.$refs.adjustmentsList.onAddItem(disburse);
                });
            },

            onComponentError(errorMessage) {
                this.errorMessage = errorMessage;
            },

            onEditReconciliation(e) {
                this.showEditReconciliationDialog();
            },

            onFinalize(e) {
                const self = this;
                if(self.readOnly || !self.enableFinalize) return;
                let onOk = function (args) {
                    let apiPromise = self.$api.ReconciliationApi.finalizeReconciliations([self.reconciliation.reconciliationID]);
                    return self.$rqBusy.wait(apiPromise)
                        .then(result => {
                            self.fetchData();
                            return true;
                        })
                        .catch(error => {
                            self.$toast.error({ message: `Error finalizing Reconciliation.` });
                            return true;
                        })
                        .finally(() => {
                            //the last finalized date is stored on the order and is used in CW and EA so refresh it
                            if (_.getNumber(self, "order.ordersID", 0) > 0) self.$store.dispatch(ORDER_ACTIONS.GET_ORDER, { orderId: self.order.ordersID, refresh: true });
                            self.refresh();
                        });
                }
                let msg = `Are you sure you want to finalize this Reconciliation?`;
                if (self.reconciliation.difference != 0) {
                    msg = '<b>Ending Balance does not match the bank statement.</b>  ' + msg;
                }
                self.$dialog.confirm("Confirm Finalize", msg, onOk, null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            onPrint(e, reportName) {
                if(this.readOnly) return;
                let startDate = this.reconciliation.reconciliationStartDate ? DateTime.fromISO(this.reconciliation.reconciliationStartDate).toFormat("MM/dd/yyyy") : null;
                let endDate = DateTime.fromISO(this.reconciliation.reconciliationDate).toFormat("MM/dd/yyyy");

                if (reportName == 'Bank Balance')
                {
                    let reportOptions = new ReportOptionsDto({
                        title: 'Book Balance',
                        parameters: {
                            p_CompanyID:        this.reconciliation.bankCompanyID || 0,
                            p_BeginningBalance: this.reconciliation.priorBookBalance || 0,
                            p_StaticStartDate:    startDate,
                            p_StaticEndDate:      endDate
                        }
                    });
                    _.invoke(this, "$rq.showReport", 'System Reports/File Specific/Book Balance', reportOptions);
                    return;
                }
                if (reportName == 'Exceptions Report')
                {
                    let reportOptions = new ReportOptionsDto({
                        title: 'Reconciliation Exceptions',
                        parameters: {
                            p_Banks:        this.reconciliation.bankCompanyID || null,
                            p_StartDate:    startDate,
                            p_EndDate:      endDate,
                            p_IsIncludeFinalized: this.reconciliation.completed ? 1 : 0
                        }
                    });
                    _.invoke(this, "$rq.showReport", 'System Reports/File Specific/Reconciliation Exceptions Report', reportOptions);
                    return;
                }
                if (reportName == '3-Way Summary')
                {
                    let reportOptions = new ReportOptionsDto({
                        title: 'Three Way Summary',
                        parameters: {
                            p_Banks:        this.reconciliation.bankCompanyID || 0,
                            p_StaticStartDate:    startDate,
                            p_StaticEndDate:      endDate,
                            p_BeginningBalance: this.reconciliation.priorBookBalance || 0
                        }
                    });
                    _.invoke(this, "$rq.showReport", 'System Reports/File Specific/Three Way Summary', reportOptions);
                    return;
                }
                if (reportName == 'Escrow Trial Balance')
                {
                    let reportOptions = new ReportOptionsDto({
                        title: 'Escrow Trial Balance',
                        parameters: {
                            p_Banks:        this.reconciliation.bankCompanyID ? this.reconciliation.bankCompanyID : '',
                            p_StaticEndDate:      endDate
                        }
                    });
                    _.invoke(this, "$rq.showReport", 'System Reports/File Specific/Escrow Trial Balance', reportOptions);
                    return;
                }
                if (reportName == 'Reconciled Bank Balance')
                {
                    let reportOptions = new ReportOptionsDto({
                        title: 'Reconciled Bank Balance',
                        parameters: {
                            p_Banks:        this.reconciliation.bankCompanyID ? this.reconciliation.bankCompanyID : '',
                            p_StaticEndDate:      endDate
                        }
                    });
                    _.invoke(this, "$rq.showReport", 'System Reports/File Specific/Reconciled Bank Balance', reportOptions);
                    return;
                }
                if (reportName == 'Bank Reconciliation')
                {
                    let reportOptions = new ReportOptionsDto({
                        title: 'Bank Reconciliation',
                        parameters: {
                            p_ReconciliationIDs:this.reconciliation.reconciliationID,
                            p_Banks:            this.reconciliation.bankCompanyID ? this.reconciliation.bankCompanyID : '',
                            p_BeginningBalance: this.reconciliation.priorBookBalance || 0,
                        }
                    });
                    _.invoke(this, "$rq.showReport", 'System Reports/File Specific/Bank Reconciliation', reportOptions);
                    return;
                }
                if (reportName == 'Full 3-Way Summary')
                {
                    let reportOptions = new ReportOptionsDto({
                        title: 'Full Three Way Summary',
                        parameters: {
                            p_Bank:        this.reconciliation.bankCompanyID || 0,
                            p_BeginningBalance: this.reconciliation.priorBookBalance || 0,
                            p_StaticStartDate:    startDate,
                            p_StaticEndDate:      endDate,
                            p_SignatureBlockOption: 2
                        }
                    });
                    _.invoke(this, "$rq.showReport", 'System Reports/Accounting/Full Three Way Summary', reportOptions);
                    return;
                }
                this.$dialog.confirm(
                `Print ${reportName}`,
                `Coming Soon`,
                null,
                null,
                { cancelTitle: 'Cancel', okTitle: 'OK'});
            },

            onRefreshItems(e) {
                const self = this;
                if(!self.enableFinalize || self.readOnly) return;
                let onOk = function (args) {
                    self.fetchData();
                }
                let msg = `Are you sure you want to refresh the Receipts &amp; Disbursements for this Reconciliation?`;
                self.$dialog.confirm("Confirm Refresh", msg, onOk, null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            onSave(e) {
                GlobalEventManager.saveCompleted({ success: true });
                return Promise.resolve(true);
            },

            onSelectAllChecks(e) {
                const self = this;
                if(self.readOnly || !self.showSelectAllChecks) return;
                let ids = _.map(self.checks, "checksID");
                self.selectChecks(self.reconciliationID, ids, true)
                    .then(data => {
                        self.errorMessage = "";
                        self.onUpdateCheckData(data);
                    })
                    .catch(error => {
                        self.errorMessage = `Error Selecting Checks.`;
                    })
                    .finally(() => {
                        self.tabIndex = self.tabs.Disbursements;
                        self.refresh('disbursementsList');
                    });
            },

            onSelectAllDeposits(e) {
                const self = this;
                if(self.readOnly || !self.showSelectAllDeposits) return;
                let ids = _.map(self.deposits, "depositID");
                self.selectDeposits(self.reconciliationID, ids, true)
                    .then(data => {
                        self.errorMessage = "";
                        self.onUpdateDepositData(data);
                    })
                    .catch(error => {
                        self.errorMessage = `Error Selecting Deposits.`;
                    })
                    .finally(() => {
                        self.tabIndex = self.tabs.Receipts;
                        self.refresh('receiptsList');
                    });
            },

            onSelectCheckRange(e) {
                this.showSelectCheckRangeDialog();
            },

            onTabActivated(e) {
                let name = _.camelCase(`${e.tab.name}List`);
                let grid = _.get(this.$refs, name);
                if (grid) {
                    grid.updateDimensions();
                    grid.refresh();
                }
            },

            onUnselectAllChecks(e) {
                const self = this;
                if(self.readOnly || !self.showUnselectAllChecks) return;
                let ids = _.map(self.checks, "checksID");
                self.selectChecks(self.reconciliationID, ids, false)
                    .then(data => {
                        self.errorMessage = "";
                        self.onUpdateCheckData(data);
                    })
                    .catch(error => {
                        self.errorMessage = `Error Selecting Checks.`;
                    })
                    .finally(() => {
                        self.tabIndex = self.tabs.Disbursements;
                        self.refresh('disbursementsList');
                    });
            },

            onUnselectAllDeposits(e) {
                const self = this;
                if(self.readOnly || !self.showUnselectAllDeposits) return;
                let ids = _.map(self.deposits, "depositID");
                self.selectDeposits(self.reconciliationID, ids, false)
                    .then(data => {
                        self.errorMessage = "";
                        self.onUpdateDepositData(data);
                    })
                    .catch(error => {
                        self.errorMessage = `Error Selecting Deposits.`;
                    })
                    .finally(() => {
                        self.tabIndex = self.tabs.Receipts;
                        self.refresh('receiptsList');
                    });
            },

            onUpdateAdjustmentData(data) {
                if (_.isEmpty(data)) return;
                this.reconciliation = new ReconciliationDto(data.reconciliation);
                this.adjustments = _.map(data.adjustments, c => new OtherDisbursementReconciliationDto(c));
            },

            onUpdateCheckData(data) {
                if (_.isEmpty(data)) return;
                this.reconciliation = new ReconciliationDto(data.reconciliation);
                this.checks = _.map(data.checks, c => new ReconciliationCheckDto(c));
                this.ledger =  _.map(_.filter(_.concat(this.checks, this.deposits), ["clearedTemp", true]), c => new ReconciliationBalanceSheetDto(c));
                if (!_.isNil(data.inTransit)) this.inTransit = _.map(data.inTransit, d => new ReconciliationInTransitDto(d));
            },

            onUpdateDepositData(data) {
                if (_.isEmpty(data)) return;
                this.reconciliation = new ReconciliationDto(data.reconciliation);
                this.deposits = _.map(data.deposits, d => new ReconciliationDepositDto(d));
                this.ledger =  _.map(_.filter(_.concat(this.checks, this.deposits), ["clearedTemp", true]), c => new ReconciliationBalanceSheetDto(c));
                this.inTransit = _.map(data.inTransit, d => new ReconciliationInTransitDto(d));
                if (!_.isNil(data.inTransit)) this.inTransit = _.map(data.inTransit, d => new ReconciliationInTransitDto(d));
            },

            onViewReconciliationList() {
                this.$router.push({ name: 'ea:reconciliations'});
            },

            refresh(name) {
                if (_.isNil(name)) {
                    name = `${_.camelCase(_.findKey(this.tabs, val => val === this.tabIndex))}List`;
                }
                let grid = _.get(this.$refs, name);
                let hasRefresh = _.isFunction(_.get(grid, 'refresh'));
                if (grid && hasRefresh) {
                    grid.refresh();
                }
            },

            setData(result) {
                const self = this;
                self.reconciliation = new ReconciliationDto(result.reconciliation);
                self.adjustments = _.map(result.adjustments, c => new OtherDisbursementReconciliationDto(c));
                self.checks = _.map(result.checks, c => new ReconciliationCheckDto(c));
                self.deposits = _.map(result.deposits, d => new ReconciliationDepositDto(d));
                self.inTransit = _.map(result.inTransit, d => new ReconciliationInTransitDto(d));
                if (self.reconciliation.completed) {
                    self.ledger =  _.map(_.concat(self.checks, self.deposits), c => new ReconciliationBalanceSheetDto(c));
                } else {
                    self.ledger =  _.map(_.filter(_.concat(self.checks, self.deposits), ["clearedTemp", true]), c => new ReconciliationBalanceSheetDto(c));
                }
            },

            selectChecks(reconciliationID, ids, select){
                const self = this;
                let apiPromise = self.$api.ReconciliationApi.selectChecks(reconciliationID, ids, select);
                return self.$rqBusy.wait(apiPromise);
            },

            selectDeposits(reconciliationID, ids, select){
                const self = this;
                let apiPromise = self.$api.ReconciliationApi.selectDeposits(reconciliationID, ids, select);
                return self.$rqBusy.wait(apiPromise);
            },

            showEditReconciliationDialog() {
                const self = this;
                if(self.readOnly) return;
                let onOk = (e) => {
                    return e.component.save()
                        .then(item => {
                            self.reconciliation = new ReconciliationDto(item);
                            self.errorMessage = "";
                            self.fetchData();
                            return true;
                        })
                        .catch(error => {
                            e.component.errorMessage = error.errorMessage;
                            return false;
                        });
                };
                self.$dialog.open({
                    title: `Edit Reconciliation`,
                    height: "auto",
                    width: "500",
                    adaptive: true,
                    closeOnEsc: true,
                    component: ReconciliationAddEditForm,
                    props: {item: _.clone(self.reconciliation)},
                    onOk: onOk
                });
            },

            showSelectCheckRangeDialog() {
                const self = this;
                if(self.readOnly || !self.showSelectAllChecks) return;
                let items = _.sortBy(_.map(_.filter(_.clone(self.checks), c => c.checkNumber > 0 && c.clearedTemp == false), "checkNumber"), "checkNumber");
                let onOk = (e) => {
                    if (!e.component.isValid()) return false;
                    let checkFrom = e.component.checkNumberFrom;
                    let checkTo = e.component.checkNumberTo;
                    let ids = _.map(_.filter(self.checks, c => _.gte(c.checkNumber, checkFrom) && _.lte(c.checkNumber, checkTo) && c.clearedTemp == false), "checksID");
                    self.selectChecks(self.reconciliationID, ids, true)
                        .then(data => {
                            self.errorMessage = "";
                            self.onUpdateCheckData(data);
                        })
                        .catch(error => {
                            self.errorMessage = `Error Selecting Checks.`;
                        })
                        .finally(() => {
                            self.tabIndex = self.tabs.Disbursements;
                            self.refresh('disbursementsList');
                        });
                };
                self.$dialog.open({
                    title: `Select Check Range`,
                    height: "auto",
                    width: "500",
                    props: {
                        items: items
                    },
                    adaptive: true,
                    closeOnEsc: true,
                    component: ReconciliationSelectCheckRange,
                    onOk: onOk
                });
            },
        }
    }
</script>