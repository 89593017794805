<template>
    <div class="content-wrapper escrow-browser">
        <rq-banner
            variant="error"
            icon="fas fa-exclamation-triangle"
            :message="errorMessage"
            :visible="errorMessage.length > 0"
            @dismiss="errorMessage=''"
            dismissable
        />
        <rq-page-section :title="`Search ${itemTypeNamePlural}`" headerSize="lg" class="browser-filter-section" v-model:expanded="filtersExpanded" collapsible>
            <template #header-actions>
                <transition name="simple-fade">
                    <ul v-if="!filtersExpanded" class="nav browser-filter-display">
                        <li class="nav-item" v-if="bankFilterDisplay != 'All'">
                            <div class="filter-name">Bank:</div>
                            <div class="filter-value">{{bankFilterDisplay}}</div>
                        </li>
                        <li class="nav-item" v-if="statusFilterDisplay != 'All'">
                            <div class="filter-name">Status:</div>
                            <div class="filter-value">{{statusFilterDisplay}}</div>
                        </li>
                        <li class="nav-item" v-if="checkFilterDisplay.length > 0">
                            <div class="filter-name">{{isWire ? "Wire" : "Check"}}#:</div>
                            <div class="filter-value">{{checkFilterDisplay}}</div>
                        </li>
                        <li class="nav-item" v-if="amountFilterDisplay.length > 0">
                            <div class="filter-name">Amount:</div>
                            <div class="filter-value">{{amountFilterDisplay}}</div>
                        </li>
                        <li class="nav-item" v-if="dateFilterDisplay.length > 0">
                            <div class="filter-name">Date:</div>
                            <div class="filter-value">{{dateFilterDisplay}}</div>
                        </li>
                        <li class="nav-item" v-if="hasActiveFilter">
                            <b-btn
                                variant="link"
                                class="btn-theme"
                                @click="onClearAllSearch">Reset All Filters
                            </b-btn>
                        </li>
                    </ul>
                </transition>
                <transition name="simple-fade">
                    <ul v-if="filtersExpanded" class="nav ms-auto">
                        <li class="nav-item">
                            <b-btn
                                automation_id="btn_search_clear"
                                variant="theme"
                                @click="onClearSearch">Reset</b-btn>
                        </li>
                        <li class="nav-item">
                            <b-btn
                                automation_id="btn_search_checks"
                                variant="theme"
                                :disabled="(errorMessage.length > 0 && !hasFilter) || v$.$error"
                                @click="onSearch">Search</b-btn>
                        </li>
                    </ul>
                </transition>
            </template>
            <div class="row">
                <div :class="{ 'col col-6 col-xl-4':true }">
                    <rq-action-form-group
                        label="Status"
                        labelFor="dtp_status"
                        :show-action="true"
                        :has-error="v$.request.escrowCheckStatusIDs.$error"
                        required
                        >
                        <template #action>
                            <button
                                type="button"
                                class="btn btn-link btn-theme"
                                @click="onClearStatus"
                                >{{filterLabel("status")}}
                            </button>
                        </template>
                        <template #default>
                            <dx-tag-box
                                ref="escrowStatusTagBox"
                                :input-attr="{ automation_id: 'dtp_status', id: 'dtp_status' }"
                                class="form-control"
                                :data-source="escrowStatuses"
                                display-expr="name"
                                value-expr="id"
                                :search-enabled="true"
                                :show-selection-controls="true"
                                :show-clear-button="true"
                                :max-displayed-tags="3"
                                :show-drop-down-button="true"
                                placeholder="All Statuses..."
                                apply-value-mode="useButtons"
                                v-model="v$.request.escrowCheckStatusIDs.$model"
                                @value-changed="onFilterChange"
                            />
                            <rq-validation-feedback
                                :container="tooltipContainer"
                                :boundary="tooltipContainer"
                                :messages="{'Status is required': v$.request.escrowCheckStatusIDs.required.$invalid }"
                            />
                        </template>
                    </rq-action-form-group>
                </div>
                <div class="col col-6 col-xl-4 form-group">
                    <rq-action-form-group
                        label="Date Range"
                        labelFor="txt_check_date_range"
                        :show-action="true"
                        >
                        <template #action>
                            <button
                                type="button"
                                class="btn btn-link btn-theme"
                                @click="onClearDateRange"
                                >{{filterLabel("date")}}
                            </button>
                        </template>
                        <template #default>
                            <rq-date-range
                                id="txt_check_date_range"
                                v-model:start-date="request.checkDateFrom"
                                v-model:end-date="request.checkDateTo"
                                match-value="before-clear"
                                match-field="end"
                                format="MM/dd/yyyy"
                                type="date"
                                show-calendar-drop-downs
                                show-clear-buttons
                                no-calendars
                                borderless
                            />
                        </template>
                    </rq-action-form-group>
                </div>
                <div class="col col-6 col-xl-4 form-group">
                    <rq-action-form-group
                        :label="checkRangeLabel"
                        labelFor="txt_check_number"
                        :show-action="true"
                        >
                        <template #action>
                            <button
                                type="button"
                                class="btn btn-link btn-theme"
                                @click="onClearNumberRange"
                                >{{filterLabel("number")}}
                            </button>
                        </template>
                        <template #default>
                            <rq-number-range
                                automation_id="txt_check_number"
                                format-type="basic"
                                :commas="false"
                                :decimals="0"
                                v-model:start-value="request.checkNumberFrom"
                                v-model:end-value="request.checkNumberTo"
                                match-value="before-clear"
                                match-field="end"
                                show-clear-buttons
                                allow-nulls
                                no-prefix
                            />
                        </template>
                    </rq-action-form-group>
                </div>
                <div class="col col-6 col-xl-4 form-group">
                    <rq-action-form-group
                        label="Amount Range"
                        labelFor="txt_check_amount"
                        :show-action="true"
                        >
                        <template #action>
                            <button
                                type="button"
                                class="btn btn-link btn-theme"
                                @click="onClearAmountRange"
                                >{{filterLabel("amount")}}
                            </button>
                        </template>
                        <template #default>
                            <rq-number-range
                                automation_id="txt_check_amount"
                                prepend-icon="fas fa-dollar-sign"
                                :decimals="2"
                                v-model:start-value="request.checkAmountFrom"
                                v-model:end-value="request.checkAmountTo"
                                match-value="before-clear"
                                match-field="end"
                                show-clear-buttons
                                input-groups
                                allow-nulls
                                no-prefix
                                commas
                            />
                        </template>
                    </rq-action-form-group>
                    <label for="txt_check_amount"></label>
                </div>
            </div>
        </rq-page-section>
        <rq-page-section title="Bank Info" headerSize="lg" class="browser-filter-section">
            <div class="row">
                <div class="col col-3 col-xl-3 form-group">
                    <label for="txt_bankReferenceNumber">Bank Ref#</label>
                    <input automation_id="txt_bankReferenceNumber"
                        class="form-control"
                        type="text"
                        v-model="bankInfo.bankReferenceNumber"
                        readonly
                        disabled
                        />
                </div>
                <div class="col col-3 col-xl-3 form-group">
                    <label for="txt_customerReferenceNumber">Ref#</label>
                    <input automation_id="txt_customerReferenceNumber"
                        class="form-control"
                        type="text"
                        :value="bankInfo.customerReferenceNumber"
                        readonly
                        disabled
                        />
                </div>
                <div class="col col-3 col-xl-3 form-group">
                    <label for="txt_transactionDescription">Description</label>
                    <input automation_id="txt_transactionDescription"
                        class="form-control"
                        type="text"
                        :value="bankInfo.transactionDescription"
                        readonly
                        disabled
                        />
                </div>
                <div class="col col-3 col-xl-3 form-group">
                    <label for="txt_transactionAmount">Amount</label>
                    <rq-input-number
                        automation_id="txt_transactionAmount"
                        ref="txt_amount"
                        formatType="money"
                        disabled
                        decimals="2"
                        input-group
                        no-prefix
                        prependIcon="fas fa-dollar-sign"
                        v-model="bankInfo.transactionAmount"
                        />
                </div>
                <div class="col col-2 col-xl-2 form-group">
                    <label for="txt_notes">Issue</label>
                    <input automation_id="txt_issue"
                        class="form-control"
                        type="text"
                        :value="bankInfo.issue"
                        readonly
                        disabled
                        />
                </div>
                <div class="col col-10 col-xl-10 form-group">
                    <label for="txt_notes">Notes</label>
                    <input automation_id="txt_notes"
                        class="form-control"
                        type="text"
                        :value="bankInfo.notes"
                        readonly
                        disabled
                        />
                </div>
            </div>
        </rq-page-section>
        <rqdx-action-data-grid
            ref="dataGrid"
            :automation_id="gridAutomationID"
            :actions="selectionActions"
            :config="gridConfig"
            :title="itemTypeNamePlural"
            title-size="sm"
            class="grid-container"
            :data-source="gridDataSource"
            @assign="onAssignItem"
            @edit="onEditItem"
            @navigate="onGotoFile"
            @rowDoubleClick="onRowDoubleClick"
            @selectionChanged="onSelectionChanged"
            export-file-name="escrow_checks_data"
            :strikethrough-if-true="['inactive']"
            hide-show-column-chooser
            integrated-search
            :persist-state="false"
            :hide-search="items.length == 0"
            rq-filters>
        </rqdx-action-data-grid>
        <address-detail-popover
            title="Payee Information"
            :target="payeePopover.target"
            container="check-browser"
            :display-number="payeePopover.detail.payeeCompanyID"
            :name="payeePopover.detail.payee"
            :address1="payeePopover.detail.payeeAddress1"
            :address2="payeePopover.detail.payeeAddress2"
            :city="payeePopover.detail.payeeCity"
            :state="payeePopover.detail.payeeState"
            :zip="payeePopover.detail.payeeZip"
            v-model:visible="payeePopover.visible"
        />
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { DateTime } from "luxon";
    import { EscrowCheckDto, AutoReconCheckSearchRequest, AutoReconTransactionTempDetailDto, AutoReconUserImportDto }  from "../models";
    import { CheckStatus, CheckStatusFilter } from '../../file/check-writing/enums';
    import AddressDetailPopover from "@/shared/components/rq/AddressDetailPopover";
    import QuickCheckForm from "../components/QuickCheckForm";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import { required } from '@vuelidate/validators';
    import { useVuelidate } from "@vuelidate/core";

    const AMOUNT_MISMATCH = "Amount Mismatch";
    const MULTIPLE_MATCHES = "Multiple Matches";
    const NO_MATCH = "No Match Found";

    export default {
        name: "CheckMatchDialog",
        components: { AddressDetailPopover },
        props: {
            bankCompanyID: { type: Number, required: true, default: 0 },
            bankInfo: { type: Object, required: true, default: () => new AutoReconTransactionTempDetailDto() },
            autoReconUserImport: { type: Object, required: true, default: () => new AutoReconUserImportDto() },
        },
        inject: ["dialogId"],
        setup: () => ({ v$: useVuelidate() }),
        data () {
            return {
                originalBankInfo: null,
                items: [],
                request: new AutoReconCheckSearchRequest(),
                originalRequest: new AutoReconCheckSearchRequest(),
                activeRequest: new AutoReconCheckSearchRequest(),
                errorMessage: "",
                escrowStatuses: [],
                selectionActions: [],
                payeePopover: { target: null, detail: {}, visible: false },
                filtersExpanded: true,
                checksID: 0
            };
        },

        created() {
            this.$emit("disable-ok");
            this.initNonReactiveVariables();
            this.initGridConfig();
            this.initSearch();
        },

        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            ...mapState({
                user: state => state.authentication.session.user
            }),
            gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", null); },
            gridAutomationID() { return this.isWire ? "tbl_auto_recon_check_wires" : "tbl_auto_recon_checks"; },
            escrowStatusTagBoxInstance() { return _.get(this, "$refs.escrowStatusTagBox.instance", null); },
            checkRangeLabel(){ return this.isWire ? "Wire# Range" : "Check# Range";},
            localSecurity(){
                return this.securitySettings.findValues([
                    "AllowEscrowAdminBrowserChange",
                    "CheckWriting_ScreenAccess"
                    ]);
            },
            hasFilter(){
                return !_.isEqual(new AutoReconCheckSearchRequest(this.request), new AutoReconCheckSearchRequest());
            },
            hasActiveFilter(){
                return !_.isEqual(new AutoReconCheckSearchRequest(this.activeRequest), new AutoReconCheckSearchRequest());
            },
            hasOriginalDates(){
                return _.isEqual(this.getDate(this.request.checkDateFrom), this.getDate(this.originalRequest.checkDateFrom))
                    && _.isEqual(this.getDate(this.request.checkDateTo), this.getDate(this.originalRequest.checkDateTo));
            },
            hasOriginalAmounts(){
                return _.isEqual(this.request.checkAmountFrom, this.originalRequest.checkAmountFrom)
                    && _.isEqual(this.request.checkAmountTo, this.originalRequest.checkAmountTo);
            },
            hasOriginalNumbers(){
                return _.isEqual(this.request.checkNumberFrom, this.originalRequest.checkNumberFrom)
                    && _.isEqual(this.request.checkNumberTo, this.originalRequest.checkNumberTo);
            },
            hasOriginalStatuses(){
                return _.isEqual(this.request.escrowCheckStatusIDs, this.originalRequest.escrowCheckStatusIDs);
            },
            hasFilterChanged(){
                return !_.isEqual(new AutoReconCheckSearchRequest(this.activeRequest), new AutoReconCheckSearchRequest(this.request));
            },
            isWire(){
                return _.parseBool(this.bankInfo.isWire);
            },
            dateFilterDisplay(){
                if (_.isNil(_.get(this, "activeRequest.checkDateFrom")) && _.isNil(_.get(this, "activeRequest.checkDateTo"))) {
                    return "";
                }
                return `${this.getDate(this.activeRequest.checkDateFrom, "Anything")} to ${this.getDate(this.activeRequest.checkDateTo, "Anything")}`;
            },
            amountFilterDisplay(){
                if (_.getNumber(this, "activeRequest.checkAmountFrom", 0) > 0 && _.getNumber(this, "activeRequest.checkAmountTo", 0) > 0) {
                    return `$${this.activeRequest.checkAmountFrom} to $${this.activeRequest.checkAmountTo}`;
                }
                if (_.getNumber(this, "activeRequest.checkAmountFrom", 0) == 0 && _.getNumber(this, "activeRequest.checkAmountTo", 0) > 0) {
                    return `Anything to $${this.activeRequest.checkAmountTo}`;
                }
                if (_.getNumber(this, "activeRequest.checkAmountFrom", 0) > 0 && _.getNumber(this, "activeRequest.checkAmountTo", 0) == 0) {
                    return `$${this.activeRequest.checkAmountFrom} to Anything`;
                }
                return "";
            },
            checkFilterDisplay(){
                if (_.getNumber(this, "activeRequest.checkNumberFrom", 0) > 0 && _.getNumber(this, "activeRequest.checkNumberTo", 0) > 0) {
                    return `${this.activeRequest.checkNumberFrom} to ${this.activeRequest.checkNumberTo}`;
                }
                if (_.getNumber(this, "activeRequest.checkNumberFrom", 0) == 0 && _.getNumber(this, "activeRequest.checkNumberTo", 0) > 0) {
                    return `Anything to ${this.activeRequest.checkNumberTo}`;
                }
                if (_.getNumber(this, "activeRequest.checkNumberFrom", 0) > 0 && _.getNumber(this, "activeRequest.checkNumberTo", 0) == 0) {
                    return `${this.activeRequest.checkNumberFrom} to Anything`;
                }
                return "";
            },
            bankFilterDisplay(){
                return this.lookupHelpers.getLookupItemName(this.lookupItems.ESCROW_ACCOUNTS, this.activeRequest.bankCompanyID);
            },
            statusFilterDisplay(){
                let itemNames = _.map(this.activeRequest.escrowCheckStatusIDs, id => _.find(this.escrowStatuses, { id }).name);
                if (_.size(itemNames) == 0) return "";
                return _.join(_.sortBy(itemNames, ['name']), ", ");
            },
            readOnly() { return !this.localSecurity.AllowEscrowAdminBrowserChange; },
            tooltipContainer() { return `#${this.dialogId}`; },
        },

        watch: {
            "v$.$error":{
                handler: function(newValue, oldValue) {
                    this.$emit(`${newValue ? "disable" : "enable"}-ok`);
                    this.errorMessage = newValue ? 'Please correct the highlighted fields.' : "";
                },
                deep: true,
                immediate: true
            },
            checksID(newValue, oldValue) {
                _.parseNumber(newValue, 0) > 0 ? this.$emit("enable-ok") : this.$emit("disable-ok");
            },
        },

        validations: () => ({
            request: {
                escrowCheckStatusIDs: {
                    required
                },
            }
        }),

        methods: {
            clear() {
                if(!this.gridInstance) return;
                this.gridInstance.clearSelection();
            },

            fetchData(preselectLast=false) {
                const self = this;
                let apiPromise = self.$api.EBankReconApi.getChecks(self.request);
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.items = _.map(result, i => new EscrowCheckDto(i));
                        self.activeRequest = new AutoReconCheckSearchRequest(self.request);
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading ${self.itemTypeNamePlural}.` });
                    })
                    .finally(() => {
                        self.refresh(preselectLast);
                    });
            },

            filterLabel(range) {
                switch (range) {
                    case "date": return this.hasOriginalDates ? "Clear" : "Revert";
                    case "status": return this.hasOriginalStatuses ? "Select All" : "Revert";
                    case "amount": return this.hasOriginalAmounts ? "Clear" : "Revert";
                    case "number": return this.hasOriginalNumbers ? "Clear" : "Revert";
                    default: return "None";
                }
            },

            getDate(dateStr, nullValue = null) {
                return _.isNullOrEmpty(dateStr) ? nullValue : DateTime.fromFormat(dateStr, "MM/dd/yyyy").toFormat("MM/dd/yyyy");
            },

            formatMoney(v) { return accounting.formatMoney(_.parseNumber(v, 0), { format: { pos:"%s%v", neg:"(%s%v)", zero:"%s%v" } }); },

            initSearch() {
                const self = this;
                self.checksID = 0;
                self.originalBankInfo = _.clone(self.bankInfo);
                self.request.bankCompanyID = self.bankCompanyID;
                self.request.isWire = self.isWire;
                self.request.autoReconUserImportID = self.autoReconUserImport.autoReconUserImportID;
                self.request.checkAmountFrom = self.request.checkAmountTo = _.parseNumber(self.bankInfo.transactionAmount, null);
                self.request.checkNumberFrom = self.request.checkNumberTo = _.parseNumber(self.bankInfo.customerReferenceNumber, null);
                self.request.checkDateFrom = DateTime.fromISO(self.autoReconUserImport.priorReconciliationDate || "01/01/2000").minus({days: self.autoReconUserImport.lookBackDays}).toFormat("MM/dd/yyyy");
                self.request.checkDateTo = DateTime.fromISO(self.autoReconUserImport.reconciliationDate).plus({days: self.autoReconUserImport.forwardDays}).toFormat("MM/dd/yyyy");
                if (_.isEqual(self.bankInfo.issue, MULTIPLE_MATCHES) || _.isEqual(self.bankInfo.issue, AMOUNT_MISMATCH)) {
                    self.request.escrowCheckStatusIDs = self.isWire ? [CheckStatus.Wire] : [CheckStatus.Issued];
                }
                if (_.isEqual(self.bankInfo.issue, NO_MATCH)) {
                    self.request.escrowCheckStatusIDs = [CheckStatusFilter.None, CheckStatusFilter.Wire, CheckStatusFilter.Issued, CheckStatusFilter.ExpectedWireOut]; //RQO-32965
                }
                if (_.isEqual(self.bankInfo.issue, AMOUNT_MISMATCH)) {
                    self.request.checkAmountFrom = self.request.checkAmountTo = null;// since this is a mismatch we already know the amounts don't match so exclude them
                }
                self.originalRequest = _.clone(self.request);
                self.fetchData();
            },

            initNonReactiveVariables() {
                const self = this;
                self.itemTypeName = self.isWire ? "Wire" : "Disbursement";
                self.itemTypeNamePlural = self.isWire ? "Wires" : "Disbursements";
                self.itemKey = "checksID";
                self.statuses = CheckStatus.lookupItems;
                self.escrowStatuses = CheckStatusFilter.lookupItems;
                self.selectionActions = [
                    {
                        name: "assign",
                        text: "Assign",
                        eventName: "assign",
                        requireSelection: true,
                        disabled: function(e) {
                            if (_.parseNumber(e.data.reconciliationID, 0) > 0) return `Cannot assign reconciled ${self.itemTypeName}`;
                            if (!_.isEqual(_.parseNumber(self.bankInfo.transactionAmount, -1), _.parseNumber(e.data.amount, 0))) return "Amounts must match";
                            if (!_.includes([CheckStatus.Issued, CheckStatus.Wire], _.parseNumber(e.data.checkStatus, 0))) return "Status must be Issued or Wire";
                            return false;
                        }
                    },
                    {
                        name: "edit",
                        text: "Edit",
                        eventName: "edit",
                        requireSelection: true,
                        disabled: function(e) {
                            return self.readOnly;
                        }
                    },
                    { name: "goto", text: "Go To File", eventName: "navigate", requireSelection: true, tooltip: `Go to ${ self.localSecurity.CheckWriting_ScreenAccess ? `Check Writing` : `File Main`} for this File.` },
                ];
            },

            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    columnHidingEnabled: false,
                    focusedRowEnabled: false,
                    paging: { enabled: true },
                    pager: { showPageSizeSelector: true, allowedPageSizes: [50,100,500], showInfo: true},
                    remoteOperations: { sorting: false, paging: false },
                    scrolling: { useNative: true },
                    selection: { mode: "single" },
                    height: "100%",
                    columns: [
                        {
                            dataField: "gfNo",
                            dataType: "string",
                            caption: "File#",
                        },
                        {
                            dataField: "checkStatus",
                            dataType: "number",
                            caption: "Status",
                            lookup: {
                                dataSource: self.statuses,
                                valueExpr: "id",
                                displayExpr: "name"
                            },
                            rqFilter: {
                                disabled: true,
                                disabledTooltip: "Use fields at the top of the page to filter this column."
                            }
                        },
                        {
                            dataField: "numberDisplay",
                            dataType: "string",
                            caption: "Number",
                        },
                        {
                            dataField: "checkDate",
                            dataType: "date",
                            caption: "Date",
                        },
                        // DxGridUtils.dateColumn({
                        //     dataField: "checkDate",
                        //     caption: "Date",
                        // }),
                        {
                            dataField: "amount",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            width: 100,
                            minWidth: 100
                        },
                        {
                            dataField: "payee",
                            dataType: "string",
                            caption: "Payee",
                            width: 200,
                            cellTemplate: DxGridUtils.infoIconCellTemplate({
                                handlers:{
                                    mouseover(cellElement, cellInfo, e) {
                                        self.updatePayeePopover(cellInfo.data, e.target);
                                    },
                                    mouseout(cellElement, cellInfo, e) {
                                        self.updatePayeePopover();
                                    }
                                }
                            })
                        },
                        {
                            dataField: "reconciliationDate",
                            dataType: "date",
                            caption: "Recon Date",
                            cellTemplate: DxGridUtils.reconDateCellTemplate
                        },
                        {
                            dataField: "stopPayDate",
                            dataType: "date",
                        },
                        {
                            dataField: "voidDate",
                            dataType: "date",
                        },
                        // DxGridUtils.dateColumn({
                        //     dataField: "stopPayDate",
                        // }),
                        // DxGridUtils.dateColumn({
                        //     dataField: "voidDate",
                        // }),
                        {
                            dataField: "usersDisplayName",
                            dataType: "string",
                            caption: "Person",
                        },
                    ],
                };

                self.gridDataSource = {
                    key: self.itemKey,
                    load (loadOptions) {
                        return Promise.resolve(self.items);
                    },
                };
            },

            onAssignItem(e) {
                if(!e || !e.data) return;
                this.errorMessage = "";
                this.checksID = e.data.checksID;
            },

            onClearAmountRange() {
                const self = this;
                if (self.hasOriginalAmounts) {
                    self.request.checkAmountFrom = null;
                    self.request.checkAmountTo = null;
                } else {
                    self.request.checkAmountFrom = self.originalRequest.checkAmountFrom;
                    self.request.checkAmountTo = self.originalRequest.checkAmountTo;
                }
            },

            onClearNumberRange() {
                const self = this;
                if (self.hasOriginalNumbers) {
                    self.request.checkNumberFrom = null;
                    self.request.checkNumberTo = null;
                } else {
                    self.request.checkNumberFrom = self.originalRequest.checkNumberFrom;
                    self.request.checkNumberTo = self.originalRequest.checkNumberTo;
                }
            },

            onClearDateRange() {
                const self = this;
                if (self.hasOriginalDates) {
                    self.request.checkDateFrom = null;
                    self.request.checkDateTo = null;
                } else {
                    self.request.checkDateFrom = self.originalRequest.checkDateFrom;
                    self.request.checkDateTo = self.originalRequest.checkDateTo;
                }
            },

            onClearStatus() {
                const self = this;
                try {
                    if (self.hasOriginalStatuses) {
                        let all = _.map(self.escrowStatuses, "id");
                        self.escrowStatusTagBoxInstance.option("value", all);
                    } else {
                        self.escrowStatusTagBoxInstance.option("value", self.originalRequest.escrowCheckStatusIDs);
                    }
                }
                catch(err) {
                    console.error(err);
                }
            },

            onClearAllSearch() {
                this.onClearSearch();
                this.filtersExpanded = true;
            },

            onClearSearch() {
                this.initSearch();
                this.escrowStatusTagBoxInstance.option("value", this.originalRequest.escrowCheckStatusIDs);
                this.activeRequest = new AutoReconCheckSearchRequest();
                this.items = [];
                this.refresh();
            },

            onEditItem(e) {
                if(!e || !e.data || this.readOnly) return;
                this.errorMessage = "";
                this.showQuickCheckDialog(e.data);
            },

            onFilterChange(e) {
                console.log('here');
                this.errorMessage = "";
                this.v$.$touch();
            },

            onGotoFile(e) {
                if(!e || !e.data) return;
                let orderId = _.parseNumber(e.data.ordersID, 0);
                let tab = 2;
                if(this.localSecurity.CheckWriting_ScreenAccess) {
                    this.$router.push({ name: "check-writing", params: { orderId, tab } });
                } else {
                    this.$router.push({ name: "oe:main", params: { orderId } });
                }
            },

            onRowDoubleClick(e) {
                if(!e || !e.data || this.readOnly) return;
                this.gridInstance.selectRows([e.data.checksID], false);
                this.onEditItem(e);
            },

            onSearch() {
                this.errorMessage = "";
                this.v$.$touch();
                if (this.v$.$error) {
                    return;
                }
                if(!this.hasFilter) {
                    this.errorMessage = `Please enter at least one value to search ${self.itemTypeNamePlural}`;
                    return;
                }
                this.fetchData();
            },

            onSelectionChanged(e) {
                if (e.selectedRowsData.length == 0) {
                    this.checksID = null;
                }
            },

            updatePayeePopover(item=null, target=null) {
                const self = this;
                self.payeePopover = { visible: false, target: null, detail: {}};
                if(_.isNil(target) || _.isNil(item)) return;
                let detail = _.pick(item, ["payeeCompanyID", "payee", "payeeAddress1", "payeeAddress2", "payeeCity", "payeeState", "payeeZip"]);
                self.$nextTick(()=>{
                    self.payeePopover = { target, detail, visible: true };
                });
            },

            refresh(preselectLast = false) {
                if(!this.gridInstance) return;
                let checksID = this.gridInstance.getSelectedRowKeys()[0];
                this.clear();
                this.gridInstance.refresh();
                if (preselectLast && _.parseNumber(checksID, 0) > 0) {
                    _.delay(() => {
                        this.gridInstance.selectRows([checksID], false);
                    }, 500);
                }
            },

            save(){
                const self = this;
                let item = _.assign(_.clone(self.bankInfo), {checksID: self.checksID, issue: null});
                let changes = self.getAuditChanges(self.originalBankInfo, item, ["ordersID", "reconciliationID"]);
                if (changes.length == 0) {
                    self.$toast.info({ message: `No Changes Detected` });
                    return Promise.resolve(true);
                }
                let apiPromise = self.$api.EBankReconApi.saveTransactionDetail(self.bankCompanyID, [item]);
                return self.$rqBusy.wait(apiPromise);
            },

            showQuickCheckDialog(item) {
                const self = this;
                let onOk = (e) => {
                    return e.component.save()
                            .then(result => {
                                self.$toast.success({ message: `${self.itemTypeName} was saved.` });
                                self.fetchData(true);
                                return true;
                            })
                            .catch(error => {
                                e.component.errorMessage = error.errorMessage;
                                console.log(error);
                                return false;
                            });
                };
                let onCancel = (e) => {
                    let form = e.component;
                    if (form.hasChanges && !form.isNew) {
                        let onDiscard = function (args) {
                            if(self.hasFilter) {
                                self.refresh();
                            }
                            return true;
                        };
                        let onSave = (e) => {
                            return form.save()
                                    .then(result => {
                                        self.$toast.success({ message: `${self.itemTypeName} was saved.` });
                                        self.fetchData();
                                        return true;
                                    })
                                    .catch(error => {
                                        form.errorMessage = error.errorMessage;
                                        console.log(error);
                                        return false;
                                    });
                        };
                        self.$dialog.confirm("Confirm Cancel",
                            `You have pending changes, do you want to save or discard changes?`,
                            onSave,
                            onDiscard,
                            { cancelTitle: 'Discard', okTitle: 'Save'});
                    }
                };
                let title = `Edit ${self.itemTypeName}: ${_.isNullOrEmpty(item.numberDisplay) ? '(blank)' : item.numberDisplay}`;
                self.$dialog.open({
                    title: title,
                    width: "800",
                    adaptive: true,
                    closeOnEsc: true,
                    component: QuickCheckForm,
                    props: {check: item},
                    // props: {check: item, bankAmount: self.bankInfo.transactionAmount},//revert to this once the feature is approved
                    onOk: onOk,
                    onCancel: onCancel
                });
            },
        }
    }
</script>