<template>
    <div class="content-wrapper">
        <div class="row">
            <rq-banner
            variant="error"
            :message=validationErrors
            icon="fas fa-exclamation-triangle"
            :visible="validationErrors.length > 0"
            />
        </div>
        <rq-page-section title="Apply Batch Payments" headerSize="lg" borderless>
            <fieldset>
                <div class="row">
                    <div class="col col-2 col-sm-12 col-md-6 col-lg-2 form-group">
                        <label class="form-control-label" for="txt_check_amount">Amount</label>
                        <div class="input-group">
                            <span class="input-group-text">$</span>
                            <rqInputNumber v-model="checkAmount" defaultValue="0" decimals="2" minValue="0" cssClass="form-control" id="txt_check_amount" automation_id="txt_check_amount"></rqInputNumber>
                        </div>
                    </div>
                    <div :class="{ 'col col-4 col-xl-3 form-group':true, 'has-error': invalidTypeFund }">
                    <label for="dtp_fund_type">Type of Funds</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'dtp_fund_type', id: 'dtp_fund_type' }"
                        :items="invoiceTypeFunds"
                        value-expr="id"
                        display-expr="name"
                        v-model="typeFundID"
                        :search-enabled="true"
                        :show-clear-button="true"
                    />
                    <rq-validation-feedback>Type of Funds Required</rq-validation-feedback>
                    </div>
                    <div :class="{'col col-2 col-sm-12 col-md-6 col-lg-2 form-group' :true }">
                        <label class="form-control-label" for="txt_reference_number">Reference Number</label>
                        <input v-model="referenceNumber" type="text" class="form-control" id="txt_reference_number" automation_id="txt_reference_number" maxlength="50"/>
                    </div>
                    <div class="col col-2 col-sm-12 col-md-6 col-lg-2 form-group">
                        <label class="form-control-label" for="txt_remainder">Remainder</label>
                        <div class="input-group">
                            <span class="input-group-text">$</span>
                            <rqInputNumber v-model="remainder" defaultValue="0" decimals="2" cssClass="form-control" id="txt_remainder" automation_id="txt_remainder" :disabled="true"></rqInputNumber>
                        </div>
                    </div>
                    <div class="col col-2 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label for="dtp_payment_date">Payment Date</label>
                        <rqdx-date-box
                            automation_id="dtp_payment_date"
                            id="dtp_payment_date"
                            v-model="paymentDate"
                            :disabled="readOnly"
                        />
                    </div>
                </div>
            </fieldset>
        </rq-page-section>
        <rqdx-action-data-grid
            ref="dataGrid"
            automation_id="tbl_invoices"
            class="mt-4"
            :data-source="gridDataSource"
            :config="gridConfig"
            export-file-name="invoice-data"
            hide-search
            rq-editable
            hide-show-column-chooser
            focus-after-insert="none"
        />
    </div>
</template>
<script>
    import { mapState, mapGetters } from "vuex";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import { DateTime } from "luxon";
    import { required } from "@vuelidate/validators";

    export default {
        name: "BatchInvoicePaymentSearch",
        props:{
            selectedInvoices: { dataType: Array}
        },
        data(){
            return{
                checkAmount: 0,
                referenceNumber: "",
                typeFundID:4,
                paymentDate: DateTime.now().toFormat("MM/dd/yyyy"),
                validationErrors: [],
                areFundsInsufficient: true
            }
        },
        computed:{
            ...mapState({
                orderId: state => state.orders.orderId,
                isReadOnly: state => _.parseBool(state.isPageReadOnly),
                isOrderLocked: state => _.getBool(state, "orders.orderSummary.isLocked")
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems",
            ]),
            gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", {}); },
            readOnly() { return this.isReadOnly || this.isOrderLocked; },
            remainder(){
                let total = _.parseNumber(this.checkAmount, 0);
                let allocated = _.sumBy(this.selectedInvoices, si => { return _.parseNumber(si.batchPaymentAmount, 0);});
                return total - allocated;
            },
            invoiceTypeFunds(){
                return this.lookupHelpers.getLookupItems(this.lookupItems.INVOICE_TYPE_FUNDS);
            },
            invalidTypeFund(){
                return _.isNullOrEmpty(this.typeFundID);
            }

        },
        created() {
            const self = this;
            self.loadGridConfig();
            self.updatePaymentDate();
        },
        watch:{
            checkAmount(newVal){
                const self = this;
                let itemCount = self.selectedInvoices.length;
                for(let x=0; x < itemCount; x++){
                    self.selectedInvoices[x].batchPaymentAmount = 0
                }

                let totalItemAmount = 0;
                for(let x=0; x < itemCount; x++){
                    totalItemAmount = self.selectedInvoices[x].balance + totalItemAmount;
                }
                if(itemCount === 0) return;

                if(newVal >= totalItemAmount){
                    for(let x=0; x < itemCount; x++){
                    self.selectedInvoices[x].batchPaymentAmount = self.selectedInvoices[x].balance;
                    }
                }
                else{
                    let x=0;
                    let currentCheckAmount = newVal;
                    while(currentCheckAmount > 0 && x < itemCount ){
                        if(currentCheckAmount < self.selectedInvoices[x].balance){
                            self.selectedInvoices[x].batchPaymentAmount = currentCheckAmount;
                            currentCheckAmount = 0;
                        }
                        else{
                        self.selectedInvoices[x].batchPaymentAmount = self.selectedInvoices[x].balance;
                        currentCheckAmount = currentCheckAmount - self.selectedInvoices[x].batchPaymentAmount;
                        }
                    x++;
                    }
                }
                self.areFundsInsufficient = newVal - totalItemAmount < 0 ? true : false;
                self.gridInstance.refresh();
            },
            paymentDate(newVal){
                this.updatePaymentDate();
            },
            invalidTypeFund(newVal){
                const self = this;
                self.validationErrors = []
                this.$emit("enable-ok");
                if(newVal){
                    self.validationErrors.push("Type of Funds is Required");
                    this.$emit("disable-ok");
                }
            }
        },
        validations() {
            return {
                typeFundID: {
                    required
                },
            };
        },
        methods:{
            loadGridConfig() {
                const self = this;
                self.gridConfig = {
                    columns: [
                        {
                            dataField: "fileNumber",
                            dataType: "string",
                            caption: "File Number",
                            allowEditing: false,
                            editorOptions:{ inputAttr: {automation_id: "txt_file_number"} },
                        },
                        {
                            dataField: "invoiceID",
                            dataType: "number",
                            caption: "Invoice ID",
                            allowEditing: false,
                            editorOptions:{ inputAttr: {automation_id: "txt_invoice_id"} },
                        },
                        {
                            dataField: "invoiceDate",
                            dataType: "date",
                            allowEditing: false,
                            editorOptions:{ inputAttr: {automation_id: "txt_invoice_date"} },
                        },
                        {
                            dataField: "balance",
                            dataType: "number",
                            caption: "Balance",
                            alignment: "right",
                            allowEditing: false,
                            editorOptions:{ inputAttr: {automation_id: "txt_balance"} },
                            cellTemplate: DxGridUtils.moneyCellTemplate
                        },
                        {
                            dataField: "payment",
                            dataType: "number",
                            caption: "Prior Payments",
                            alignment: "right",
                            allowEditing: false,
                            editorOptions:{ inputAttr: {automation_id: "txt_prior_payments"} },
                            cellTemplate: DxGridUtils.moneyCellTemplate
                        },
                        {
                            dataField: "note1",
                            dataType: "string",
                            caption: "Notes",
                            editorOptions:{ inputAttr: {automation_id: "txt_notes"} },
                        },
                        {
                            dataField: "batchPaymentAmount",
                            dataType: "number",
                            caption: "Payment",
                            alignment: "right",
                            editorOptions:{ inputAttr: {automation_id: "txt_payment"} },
                            cellTemplate: DxGridUtils.moneyCellTemplate

                        },
                        {
                            dataField: "batchPaymentDate",
                            dataType: "date",
                            caption: "Payment Date",
                            allowEditing: false,
                            editorOptions:{ inputAttr: {automation_id: "txt_payment_date"} },
                        },
                    ],
                };

                self.gridDataSource = {
                    key: "invoiceID",
                    load (loadOptions) {
                        return Promise.resolve(self.selectedInvoices);
                    },
                    update: self.onGridUpdate
                };
            },

            onGridUpdate(key, values) {
                const self = this;
                let item = _.find(self.selectedInvoices, si => si.invoiceID === key);
                _.assign(item, values);

                return Promise.resolve(item);
            },
            saveEditData() {
                const self = this;
                self.gridInstance.saveEditData();
            },

            updatePaymentDate(){
                const self = this;
                _.forEach(self.selectedInvoices, i => {
                    i.batchPaymentDate = self.paymentDate;
                });

                self.$nextTick().then(() => {
                    self.gridInstance.refresh();
                });
            },
        }
    }
</script>