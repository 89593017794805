<template>
    <div class="content-wrapper">
        <rq-banner
            ref="errorBanner"
            :message="errorMessage"
            variant="error"
            icon="fas fa-exclamation-triangle"
            :visible="errorMessage.length > 0"
        />
        <rq-banner
            :message="warnMessage"
            variant="warn"
            icon="fas fa-exclamation-triangle"
            :visible="warnMessage.length > 0"
        />
        <rq-page-section borderless>
            <fieldset :disabled="readOnly">
                <div class="row">
                    <div :class="{ 'col col-12 form-group form-required':true, 'has-error':v$.item.bankCompanyID.$error }">
                        <label for="drp_escrow_acct">Escrow Account</label>
                        <rqSelectBox
                            automation_id="drp_escrow_acct"
                            :items="escrowBanks"
                            :defaultItemEnabled="true"
                            :disabled="!isNew"
                            v-model="v$.item.bankCompanyID.$model"
                            @change="onEscrowBankChange"
                        />
                        <rq-validation-feedback
                            :container="tooltipContainer"
                            :boundary="tooltipContainer"
                            :messages="{ 'Escrow Account is required': v$.item.bankCompanyID.$error }"
                        />
                    </div>
                </div>
                <div class="row" v-show="showPriorReconciliations">
                    <div :class="{ 'col col-12 form-group': true, 'form-required': !item.isFirstRecon, 'has-error': v$.item.priorReconciliationID.$error }">
                        <label for="drp_prior_reconciliation">Prior Reconciliation</label>
                        <rqSelectBox
                            automation_id="drp_prior_reconciliation"
                            :items="priorReconciliationList"
                            :defaultItemEnabled="true"
                            :disabled="readOnly || !hasReconciliationDate || item.isFirstRecon"
                            v-model="v$.item.priorReconciliationID.$model"
                            @change="onPriorReconciliationChange"
                            value-expr="id"
                            display-expr="name"
                            :wrap-item-text="true"
                            :show-clear-button="false"
                            :search-enabled="true"
                        />
                        <rq-validation-feedback
                            :container="tooltipContainer"
                            :boundary="tooltipContainer"
                            :messages="{ 'Prior Reconciliation is required': v$.item.priorReconciliationID.isValid.$invalid }"
                        />
                    </div>
                </div>
                <div class="row">
                    <div :class="{ 'col col-8 form-group form-required':true, 'has-error':v$.item.reconciliationDate.$error }">
                        <label for="dtp_reconciliation_date">Thru Date</label>
                        <rqdx-date-box
                            id="dtp_reconciliation_date"
                            :disabled="!localSecurity.IsEscrowAdmin || isFinalized"
                            :disabled-dates="getDisabledThruDates"
                            v-model="v$.item.reconciliationDate.$model"
                            @valueChanged="onThruDateChanged"
                        />
                        <rq-validation-feedback
                            :container="tooltipContainer"
                            :boundary="tooltipContainer"
                            :messages="{ 'Thru Date is required': v$.item.reconciliationDate.required.$invalid,
                                         'A Reconciliation for that Escrow Account and Thru Date already exists.': v$.item.reconciliationDate.isValid.$invalid }"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col col-8 form-group">
                        <rq-action-form-group
                            action-class="overridden"
                            label="Statement Beginning Balance"
                            labelFor="txt_beginning_balance"
                            :show-action="isBeginningBalanceOverride"
                            @action-click="onBeginningBalanceRevert"
                            action-automation-id="txt_amount_revert"
                            action-label="Revert"
                        >
                            <template #default>
                                <rqInputNumber
                                    automation_id="txt_beginning_balance"
                                    formatType="money"
                                    defaultValue="0"
                                    decimals="2"
                                    input-group
                                    no-prefix
                                    :disabled="readOnly"
                                    prependIcon="fas fa-dollar-sign"
                                    v-model="v$.item.beginningBalance.$model"
                                />
                            </template>
                        </rq-action-form-group>
                    </div>
                </div>
                <div class="row">
                    <div class="col col-8 form-group">
                        <label for="txt_amount">Statement Ending Balance</label>
                        <rqInputNumber
                            automation_id="txt_ending_balance"
                            formatType="money"
                            defaultValue="0"
                            decimals="2"
                            input-group
                            no-prefix
                            :disabled="readOnly"
                            prependIcon="fas fa-dollar-sign"
                            v-model="v$.item.workingEndBal.$model"
                        />
                    </div>
                </div>
            </fieldset>
        </rq-page-section>
    </div>
</template>

<script>
    import { mapGetters, mapState } from "vuex";
    import { SystemLookupItem } from "@/shared/models/models";
    import { ReconciliationDto }  from "../models";
    import { required, minValue } from '@vuelidate/validators';
    import { useVuelidate } from "@vuelidate/core";
    import DateTimeHelper from "@/shared/utilities/DateTimeHelper";

    export default {
        name: 'ReconciliationAddEditForm',
        props: {
            item: { type: Object, required: true, default: () => (new ReconciliationDto())},
        },
        inject: ["dialogId"],
        setup: () => ({ v$: useVuelidate() }),
        data() {
            return {
                errorMessage: "",
                escrowBanks: [],
                originalItem: new ReconciliationDto(),
                warnMessage: "",
                priorReconciliations: [],
                originalBeginningBalance: 0
            }
        },
        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            ...mapState({
                user: state => state.authentication.session.user,
                isPageReadOnly: state => state.isPageReadOnly
            }),
            localSecurity(){
                return this.securitySettings.findValues([
                    "AllowFinalizedReconModifications",
                    "IsAdmin",
                    "IsEscrowAdmin"
                ]);
            },
            isNew() { return _.getNumber(this, "item.reconciliationID", 0) == 0; },
            isBeginningBalanceOverride() { return !_.isEqual(this.item.beginningBalance, this.originalBeginningBalance); },
            isFinalized() { return _.parseBool(this.item.completed); },
            readOnly() { return _.parseBool(this.item.completed) && !this.localSecurity.AllowFinalizedReconModifications; },
            hasReconciliationDate() {
                return _.size(this.item.reconciliationDate) > 0;
            },
            hasPriorReconciliations() {
                return _.size(this.priorReconciliations) > 0;
            },
            priorReconciliationList() {
                return _.map(_.filter(this.priorReconciliations, r => r.completed), i => new SystemLookupItem({id: i.reconciliationID, name: `Recon ID ${i.reconciliationID} through ${DateTimeHelper.getDateFromString(i.reconciliationDate).toFormat("MM/dd/yyyy")}`}));
            },
            reconciliationDates() {
                if (_.size(this.priorReconciliations) == 0) return [];
                return _.map(this.priorReconciliations, i => DateTimeHelper.getDateFromString(i.reconciliationDate).toFormat("MM/dd/yyyy"));
            },
            reconDateIsValid(){
                if (_.isNil(this.reconciliationDates) || _.isNil(this.item.reconciliationDate) || !this.isNew) return true;
                let reconDay = DateTimeHelper.getDateFromString(this.item.reconciliationDate).toFormat("MM/dd/yyyy");
                return !_.includes(this.reconciliationDates, reconDay);
            },
            showPriorReconciliations() {
                return this.hasPriorReconciliations && !this.item.isFirstRecon;
            },
            tooltipContainer() {
                return `#${this.dialogId}`;
            },
        },
        validations: () => ({
            item: {
                reconciliationDate: {
                    required,
                    isValid(val) {
                        return this.reconDateIsValid;
                    },
                },
                priorReconciliationID: {
                    isValid(val) {
                        return (!this.hasPriorReconciliations) || _.parseBool(this.item.isFirstRecon, false) || (_.parseNumber(this.item.priorReconciliationID, 0) > 0);
                    },
                },
                bankCompanyID: { required, minValue: minValue(1) },
                beginningBalance: { },
                workingEndBal: { }
            }
        }),
        created(){
            this.init();
        },
        methods: {
            getDisabledThruDates(e) {
                let currentDay = DateTimeHelper.toFormat(e.date, "MM/dd/yyyy");
                return _.includes(this.reconciliationDates, currentDay);
            },

            getPriorReconciliations(){
                const self = this;
                if (_.isNil(self.item.reconciliationDate) || _.isNil(self.item.bankCompanyID)) return;
                // We want all reconciliations scheduled in the past, as well as reconciliations already scheduled two months into the future
                let thruDate = DateTimeHelper.getDateFromString(DateTimeHelper.nowClientStartOfDay()).plus({ months: 2 }).plus({days: 1}).toFormat('MMddyyyy');
                let apiPromise = self.$api.ReconciliationApi.getPriorReconciliations(self.item.bankCompanyID, thruDate);
                self.$rqBusy.wait(apiPromise)
                    .then(items => {
                        self.priorReconciliations = _.filter(_.map(items, i => new ReconciliationDto(i)), r => r.reconciliationID != self.item.reconciliationID);
                        self.setPriorReconciliation();
                        self.$nextTick(() => {
                            if(!_.includes(self.reconciliationDates, self.item.reconciliationDate)) return;
                            self.item.reconciliationDate = null;
                        });
                    })
                    .catch(error => {
                        self.errorMessage = "Error retrieving Bank Beginning Balance";
                        self.$emit(`disable-ok`);
                    });
            },

            getPriorReconciliation(reconciliationID) {
                let prior = _.find(this.priorReconciliations, ['reconciliationID', reconciliationID]);
                return prior || new ReconciliationDto();
            },

            init() {
                const self = this;
                self.escrowBanks = self.lookupHelpers.getLookupItems(self.lookupItems.USER_ESCROW_ACCOUNTS);
                self.originalItem = _.clone(self.item);
                self.originalBeginningBalance = self.originalItem.beginningBalance;
                self.getPriorReconciliations();
            },

            onBeginningBalanceRevert(e) {
                this.item.beginningBalance = this.originalBeginningBalance;
            },

            onEscrowBankChange(e) {
                const self = this;
                self.errorMessage = "";
                self.item.bankCompanyID = e.selectedValue;
                self.priorReconciliations = [];
                if (_.parseNumber(e.selectedValue, 0) <= 0) return;
                self.getPriorReconciliations();
            },

            onPriorReconciliationChange(e) {
                const self = this;
                self.item.priorReconciliationID = _.parseNumber(e.selectedValue, null);
                self.setPriorReconciliation();
            },

            onThruDateChanged(e) {
                const self = this;
                if (!_.isNil(e.value)) {
                    self.getPriorReconciliations();
                } else {
                    self.item.priorReconciliationID = null;
                }
            },

            save() {
                const self = this;
                self.v$.$touch();
                if (self.v$.$error) { return Promise.reject({errorMessage: 'Please provide all required fields.'}); }

                let changes = self.getAuditChanges(self.originalItem, self.item);
                if (changes.length == 0 && !self.isNew) {
                    return Promise.resolve(self.item);
                }
                let apiPromise = self.$api.ReconciliationApi.save(self.item, changes);
                return self.$rqBusy.wait(apiPromise);
            },

            setPriorReconciliation() {
                const self = this;
                let priorRecon = self.getPriorReconciliation(self.item.priorReconciliationID);
                if (self.isNew) {
                    if (priorRecon.reconciliationID > 0) {
                        self.item.beginningBalance = self.originalItem.beginningBalance = priorRecon.endingBalance;
                        self.item.priorReconciliationID = priorRecon.reconciliationID;
                    } else {
                        self.item.beginningBalance = 0;
                    }
                } else {
                    self.originalBeginningBalance = priorRecon.endingBalance;
                }
            },
        }
    }
</script>