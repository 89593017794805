
import ProcessEBankRecon from "./views/ProcessEBankRecon";
import CheckBrowserList from "./views/CheckBrowserList";
import ConsolidatedChecks from "./views/ConsolidatedChecks";
import BatchInvoicePaymentSearch from './views/BatchInvoicePaymentSearch';
import DepositBrowserList from "./views/DepositBrowserList";
import CmERemit from "./views/CmERemit";
import CmERemitForm from "./components/CmERemitForm";
import EscrowTrialBalanceList from "./views/EscrowTrialBalanceList";
import EscrowActivityList from "./views/EscrowActivityList";
import ExpectedWiresList from "./views/ExpectedWiresList";
import ExpectedWiresOutList from "./views/ExpectedWiresOutList";
import OpenInvestmentAccountsList from "./views/OpenInvestmentAccountsList";
import PositivePayList from "./views/PositivePayList";
import ReconciliationList from "./views/ReconciliationList";
import ReconciliationForm from "./views/ReconciliationForm";
import UnverifiedDepositsList from "./views/UnverifiedDepositsList";
import MoneyMovementApprovalList from "./views/MoneyMovementApprovalList";
const EpicorIntegrationWrapper = () => import(/* webpackChunkName: "data-conversion" */"@/integrations/wrappers/EpicorIntegrationWrapper.vue");

let routes = [
    {
        name: "ea:disbursement-browser",
        path: "disbursement-browser",
        component: CheckBrowserList,
        meta: {
            permissionKeys: ["appEscrowAccounting_AppAccess"],
            label: "Disbursement Browser",
            logRouteAccess: true,
            scrollLayout: false,
            skipSaveOnLeave: true,
        }
    },
    {
        name: "ea:invoice-batch-payments",
        path: "invoice-batch-payments",
        component: BatchInvoicePaymentSearch,
        meta: {
            accessKeys: ["Invoices_ScreenAccess"],
            label: "Invoice Batch Payments",
            logRouteAccess: true,
            scrollLayout: false,
            skipSaveOnLeave: true,
        }
    },
    {
        name: "ea:receipt-browser",
        path: "receipt-browser",
        component: DepositBrowserList,
        meta: {
            permissionKeys: ["appEscrowAccounting_AppAccess"],
            label: "Receipt Browser",
            logRouteAccess: true,
            scrollLayout: false,
            skipSaveOnLeave: true,
        }
    },
    {
        name: "ea:escrow-activity",
        path: "escrow-activity",
        component: EscrowActivityList,
        meta: {
            permissionKeys: ["appEscrowAccounting_AppAccess"],
            label: "Escrow Activity",
            logRouteAccess: true,
            scrollLayout: false,
            skipSaveOnLeave: true,
        }
    },
    {
        name: "ea:expected-wires",
        path: "expected-wires",
        component: ExpectedWiresList,
        meta: {
            permissionKeys: ["appEscrowAccounting_AppAccess"],
            label: "Expected Wires",
            logRouteAccess: true,
            scrollLayout: false,
            skipSaveOnLeave: true,
        }
    },
    {
        name: "ea:expected-wires-out",
        path: "expected-wires-out",
        component: ExpectedWiresOutList,
        meta: {
            permissionKeys: ["appEscrowAccounting_AppAccess"],
            label: "Expected Wires Out",
            logRouteAccess: true,
            scrollLayout: false,
            skipSaveOnLeave: true,
        }
    },
    {
        name: "ea:positive-pay",
        path: "positive-pay",
        component: PositivePayList,
        meta: {
            permissionKeys: ["appPositivePay_AppAccess"],
            label: "Positive Pay",
            logRouteAccess: true,
            scrollLayout: false,
            skipSaveOnLeave: true,
        }
    },
    {
        name: "ea:investment-accounts",
        path: "investment-accounts",
        component: OpenInvestmentAccountsList,
        meta: {
            accessKeys: ["InvestmentAccountIndividual_ScreenAccess"],
            label: "Open Investment Accounts",
            logRouteAccess: true,
            scrollLayout: false,
            skipSaveOnLeave: true,
        }
    },
    {
        name: "ea:ebank-recon",
        path: "ebank-recon",
        component: ProcessEBankRecon,
        meta: {
            permissionKeys: ["appEBankRecon_AppAccess"],
            accessKeys: ["EBankRecon_ScreenAccess"],
            label: "eBank Reconciliation",
            logRouteAccess: true,
            scrollLayout: false,
            skipSaveOnLeave: true,
        }
    },
    {
        name: "ea:e-remit",
        path: "e-remit",
        component: CmERemit,
        meta: {
            permissionKeys: [],
            accessKeys: [],
            featureFlag: "eRemit",
            label: "eRemit",
            logRouteAccess: true,
            scrollLayout: false,
            skipSaveOnLeave: true,
        }
    },
    {
        name: "ea:e-remit-fragment",
        path: "e-remit-fragment",
        component: CmERemitForm,
        meta: {
            permissionKeys: [],
            accessKeys: [],
            featureFlag: "eRemitFragment",
            label: "eRemit",
            logRouteAccess: true,
            scrollLayout: false,
            skipSaveOnLeave: true,
        }
    },
    {
        name: "ea:reconciliations",
        path: "reconciliations",
        component: ReconciliationList,
        meta: {
            // accessKeys: ["Notes_ScreenAccess"],
            permissionKeys: ["appEscrowAccounting_AppAccess"],
            label: "Reconciliation",
            logRouteAccess: true,
            scrollLayout: false,
            skipSaveOnLeave: true,
        }
    },
    {
        name: "ea:reconciliation",
        path: "reconciliation/:reconciliationID",
        component: ReconciliationForm,
        meta: {
            permissionKeys: ["appEscrowAccounting_AppAccess"],
            label: "Reconciliation",
            logRouteAccess: true,
            scrollLayout: false,
            skipSaveOnLeave: false,
        }
    },
    {
        name: "ea:receipt-verification",
        path: "receipt-verification",
        component: UnverifiedDepositsList,
        meta: {
            permissionKeys: ["appEscrowAccounting_AppAccess"],
            label: "Receipt Verification",
            logRouteAccess: true,
            scrollLayout: false,
            skipSaveOnLeave: true,
        }
    },
    {
        name: "ea:consolidated-checks",
        path: "consolidated-checks",
        component: ConsolidatedChecks,
        meta: {
            accessKey: "Consolidated_ScreenAccess",
            label: "Consolidated Checks",
            logRouteAccess: true,
            scrollLayout: false,
            skipSaveOnLeave: true,
        }
    },
    {
        name: "ea:etb-browser",
        path: "etb-browser",
        component: EscrowTrialBalanceList,
        meta: {
            permissionKeys: ["appEscrowAccounting_AppAccess"],
            label: "Current ETB Browser",
            logRouteAccess: true,
            scrollLayout: false,
            skipSaveOnLeave: true,
        }
    },
    {
        name: "ea:pending-approvals",
        path: "pending-approvals",
        component: MoneyMovementApprovalList,
        meta: {
            permissionKeys: ["appFinancialApprovals_AppAccess"],
            label: "Pending Approvals",
            logRouteAccess: true,
            scrollLayout: false,
            skipSaveOnLeave: true,
        }
    },
    {
        name: "ea:workday-integration",
        path: "workday-integration",
        component: EpicorIntegrationWrapper,
        meta: {
            permissionKeys: ["appEscrowAccounting_AppAccess"],
            label: "WorkDay Integration",
            featureFlag: "epicorIntegration",
            logRouteAccess: true,
            scrollLayout: false,
            skipSaveOnLeave: true,
        }
    }
];

export default routes;
